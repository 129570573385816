import React from 'react';
import { PageProps } from 'gatsby';

import '@/scss/app.scss';

import SEO from '@/components/SEO';
import AppHeader from '@/components/AppHeader';
import Workspace from '@/components/Workspace';

import { AppContextProvider } from '@/context';

const Home: React.FC<PageProps> = ({ location }) => (
  <div className="master">
    <SEO title="Fredrik Kallevik" pathname={location.pathname} />
    <AppHeader pathname={location.pathname} />
    <main role="main" className="ws-1">
      <AppContextProvider>
        <Workspace />
      </AppContextProvider>
    </main>
  </div>
);

export default Home;
