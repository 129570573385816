import React from 'react';
import { AppContext } from '@/context';

const Workspace = () => {
  const context = React.useContext(AppContext);
  const { ws1 } = context;

  return (
    <div className="d-lg-flex">
      {ws1.wdws.map((wdw, index) => (
        <div className="ws-col" key={wdw.id}>
          <wdw.Comp wdw={wdw} index={index} />
        </div>
      ))}
    </div>
  );
};

export default Workspace;
